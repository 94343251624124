<template>
    <div class="container-fluid">
        <div class="row py-2">
            <div class="col-6 float-left">
                <h3>#施設一覧</h3>
            </div>
            <div class="col-6 float-right">
                <button type="button"
                        class="btn btn-outline-dark float-right mb-2"
                        @click="openCreateModal">＋
                </button>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12">
                <facility-list :facilities="facilities"
                               :offices="offices"
                               :meta="meta"
                               @paginate="paginate"
                               @facility-has-updated="updateFacility"
                               @facility-has-deleted="deleteFacility"
                               @facility-has-restored="restoreFacility">
                </facility-list>
            </div>
        </div>
        <create-facility-modal ref="createModal"
                               :offices="offices"
                               @facility-has-created="createFacility">
        </create-facility-modal>
    </div>
</template>

<script>
import FacilityList from "@/components/Facilities/FacilityList/FacilityList";
import CreateFacilityModal from "@/components/Facilities/CreateFacilityModal/CreateFacilityModal";
import ApiRouter from "@/components/Systems/ApiRouter";
import ErrorHandler from "@/components/Systems/ErrorHandler";

export default {
    name: "Facilities",
    components: {CreateFacilityModal, FacilityList},
    mixins: [ApiRouter, ErrorHandler],
    data: function () {
        return {
            facilities: {},
            offices: {},
            meta: {}
        }
    },
    created: function () {
        this.loadFacilities();
        this.loadOffices();
    },
    methods: {
        openCreateModal: function () {
            this.$refs['createModal'].openModal();
        },
        loadFacilities: function () {
            axios.get(this.routes.facilities)
                .then(response => {
                    this.facilities = response.data.data;
                    this.meta = response.data.meta;
                })
                .catch(error => {
                    this.handleErrorStatusCode(error);
                });
        },
        loadOffices: function () {
            axios.get(this.routes.officeAll)
                .then(response => {
                    this.offices = response.data.data;
                })
                .catch(error => {
                    this.handleErrorStatusCode(error);
                });
        },
        paginate: function (page) {
            axios.get(this.routes.facilities, {
                params: {
                    page: page
                }
            })
                .then(response => {
                    this.facilities = response.data.data;
                    this.meta = response.data.meta;
                })
                .catch(error => {
                    this.handleErrorStatusCode(error);
                });
        },
        createFacility: function (facility) {
            this.facilities.unshift(facility);
        },
        updateFacility: function ($event) {
            const index = this.facilities.findIndex(function (facility) {
                return facility.id === $event.id;
            });
            if (index !== -1) {
                this.facilities.splice(index, 1, $event);
            }
        },
        deleteFacility: function ($event) {
            const index = this.facilities.findIndex(function (facility) {
                return facility.id === $event.id;
            });
            this.facilities.splice(index, 1);

            this.facilities.push($event);
        },
        restoreFacility: function ($event) {
            const index = this.facilities.findIndex(function (facility) {
                return facility.id === $event.id;
            });
            if (index !== -1) {
                this.facilities.splice(index, 1, $event);
            }
        },
    }
}
</script>

<style scoped>

</style>
