<template>
    <div>
        <table class="table table-bordered bg-white">
            <thead class="bg-dark">
            <tr>
                <th style="width: 20%">施設名</th>
                <th style="width: 20%">サービス</th>
                <th style="width: 20%">住所</th>
                <th style="width: 20%">事業所名</th>
                <th style="width: 10%">編集</th>
                <th style="width: 10%">削除</th>
            </tr>
            </thead>
            <tbody>
            <template v-for="facility in facilities">
                <tr :class="{'is-deleted': (facility.attributes.deleted_at !== null)}">
                    <td>{{ facility.attributes.name }}</td>
                    <td>{{ castServiceType(facility.attributes.service_type) }}</td>
                    <td>{{ facility.attributes.address }}</td>
                    <td>{{ facility.relationships.office.attributes.name }}</td>
                    <template v-if="facility.attributes.deleted_at === null">
                        <td><a href="javascript:void(0)" @click="openEditModal(facility)">編集</a></td>
                        <td><a href="javascript:void(0)" @click="openDeleteModal(facility)">削除</a></td>
                    </template>
                    <template v-else>
                        <td colspan="2" class="text-center"><a href="javascript:void(0)" @click="restoreConfirm(facility)">復元</a></td>
                    </template>
                </tr>
            </template>
            </tbody>
        </table>
        <pagination :meta="meta" @paginate="$emit('paginate', $event)"></pagination>
        <edit-facility-modal ref="editModal"
                             :offices="offices"
                             @facility-has-updated="$emit('facility-has-updated', $event)">
        </edit-facility-modal>
        <delete-facility-modal ref="deleteModal" @facility-has-deleted="$emit('facility-has-deleted', $event)">
        </delete-facility-modal>
    </div>
</template>

<script>
import EditFacilityModal from "@/components/Facilities/EditFacilityModal/EditFacilityModal";
import DeleteFacilityModal from "@/components/Facilities/DeleteFacilityModal/DeleteFacilityModal";
import Pagination from "@/components/Pagination/Pagination";
import ApiRouter from "@/components/Systems/ApiRouter";
import ErrorHandler from "@/components/Systems/ErrorHandler";

export default {
    name: "FacilityList",
    components: {DeleteFacilityModal, EditFacilityModal, Pagination},
    mixins: [ApiRouter, ErrorHandler],
    props: ['facilities', 'offices', 'meta'],
    methods: {
        openEditModal: function (facility) {
            this.$refs['editModal'].openModal(facility);
        },
        openDeleteModal: function (facility) {
            this.$refs['deleteModal'].openModal(facility);
        },
        restoreFacility: function (facility) {
            this.showIndicator('復元中');

            axios.patch(this.routes.facilityRestore(facility.id))
                .then(response => {
                    this.showSuccessPopup('復元しました');
                    this.$emit('facility-has-restored', response.data.data);
                })
                .catch(error => {
                    this.handleErrorStatusCode(error);
                });
        },
        restoreConfirm: function (facility) {
            this.showRestoreConfirmPopup(() => {
                this.restoreFacility(facility);
            })
        }
    },
    computed: {
        castServiceType: function () {
            return function (serviceType) {
                switch (serviceType) {
                    case '0':
                        return '就労移行支援';
                    case '1':
                        return '放課後等デイサービス';
                    default:
                        return '';
                }
            }
        }
    }
}
</script>

<style src="./FacilityList.scss" lang="scss" scoped/>
