<template>
    <CModal
        title="施設削除"
        size="sm"
        color="dark"
        :show.sync="isOpened">
        <div class="py-1">施設名：{{ name }}</div>
        <div class="py-1">サービス：{{ castServiceType(serviceType) }}</div>
        <div class="py-1">住所：{{ address }}</div>
        <div class="py-1">事業所名：{{ officeName }}</div>
        <template #footer>
            <button type="button" class="btn btn-danger" @click="deleteConfirm" :disabled="sending">削除</button>
        </template>
    </CModal>
</template>

<script>
import ApiRouter from "@/components/Systems/ApiRouter";
import ErrorHandler from "@/components/Systems/ErrorHandler";
export default {
    name: "DeleteFacilityModal",
    mixins: [ApiRouter, ErrorHandler],
    data: function () {
        return {
            isOpened: false,
            sending: false,
            id: '',
            name: '',
            serviceType: '',
            address: '',
            officeName: ''
        }
    },
    methods: {
        openModal: function (facility) {
            this.isOpened = true;
            this.id = facility.id;
            this.name = facility.attributes.name;
            this.serviceType = facility.attributes.service_type;
            this.address = facility.attributes.address;
            this.officeName = facility.relationships.office.attributes.name;
        },
        deleteFacility: function () {
            this.showIndicator('削除中');
            this.sending = true;

            axios.delete(this.routes.facility(this.id))
                .then(response => {
                    this.$emit('facility-has-deleted', response.data.data);
                    this.showSuccessPopup('削除しました');
                    this.clearErrors();
                    this.isOpened = false;
                })
                .catch(error => {
                    this.handleErrorStatusCode(error);
                })
                .finally(() => {
                    this.sending = false;
                });
        },
        deleteConfirm: function () {
            this.showDeleteConfirmPopup(() => {
                this.deleteFacility();
            })
        }
    },
    computed: {
        castServiceType: function () {
            return function (serviceType) {
                switch (serviceType) {
                    case '0':
                        return '就労移行支援';
                    case '1':
                        return '放課後等デイサービス';
                    default:
                        return '';
                }
            }
        }
    }
}
</script>

<style scoped>

</style>
