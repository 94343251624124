<template>
    <CModal
        title="施設登録"
        size="lg"
        color="dark"
        :show.sync="isOpened">
        <form>
            <div class="form">
                <v-input label="施設名"
                         id="name"
                         type="text"
                         placeholder="入力必須"
                         :errors="hasErrors('name')"
                         v-model="form.name">
                </v-input>
                <v-select id="create-service"
                          label="サービス"
                          :errors="hasErrors('service_type')"
                          v-model="form.service_type">
                    <option :selected="form.service_type === ''">選択してください</option>
                    <option value="0" :selected="form.service_type === '0'">就労移行支援</option>
                    <option value="1" :selected="form.service_type === '1'">放課後等デイサービス</option>
                </v-select>
                <v-input label="住所"
                         id="create-address"
                         type="text"
                         placeholder="入力必須"
                         :errors="hasErrors('address')"
                         v-model="form.address">
                </v-input>
                <v-select id="create-office"
                          label="事業所"
                          :errors="hasErrors('office_id')"
                          v-model="form.office_id">
                    <option value="" :selected="form.office_id === ''">選択してください</option>
                    <option :value="office.id" v-for="office in offices"
                            :key="office.id"
                            :selected="form.office_id === office.id">
                        {{ office.attributes.name }}
                    </option>
                </v-select>
            </div>
        </form>
        <template #footer>
            <button type="button" class="btn btn-dark" @click="createFacility" :disabled="sending">登録</button>
        </template>
    </CModal>
</template>

<script>
import VInput from "@/components/Forms/VInput";
import VSelect from "@/components/Forms/VSelect";
import ApiRouter from "@/components/Systems/ApiRouter";
import ErrorHandler from "@/components/Systems/ErrorHandler";

export default {
    name: "CreateFacilityModal",
    components: {VSelect, VInput},
    mixins: [ApiRouter, ErrorHandler],
    props: ['offices'],
    data: function () {
        return {
            isOpened: false,
            sending: false,
            form: {
                name: '',
                address: '',
                office_id: '',
                service_type: ''
            }
        }
    },
    methods: {
        openModal: function () {
            this.isOpened = true;
            this.initForm();
            this.clearErrors();
        },
        initForm: function () {
            this.form.name = '';
            this.form.address = '';
            this.form.office_id = '';
            this.form.service_type = '';
        },
        createFacility: function () {
            this.showIndicator('登録中');
            this.sending = true;

            axios.post(this.routes.facilities, this.form)
                .then(response => {
                    this.$emit('facility-has-created', response.data.data);
                    this.showSuccessPopup('登録しました');
                    this.clearErrors();
                    this.isOpened = false;
                })
                .catch(error => {
                    this.handleErrorStatusCode(error);
                })
                .finally(() => {
                    this.sending = false;
                });
        }
    }
}
</script>

<style scoped>

</style>
