<template>
    <CModal
        title="施設編集"
        size="lg"
        color="dark"
        :show.sync="isOpened">
        <form>
            <div class="form">
                <v-input label="施設名"
                         id="edit-name"
                         type="text"
                         placeholder="入力必須"
                         v-model="form.name">
                </v-input>
                <v-select id="edit-service"
                          label="サービス"
                          :errors="hasErrors('service_type')"
                          v-model="form.service_type">
                    <option value="0" :selected="form.service_type === '0'">就労移行支援</option>
                    <option value="1" :selected="form.service_type === '1'">放課後等デイサービス</option>
                </v-select>
                <v-input label="住所"
                         id="edit-address"
                         type="text"
                         placeholder="入力必須"
                         v-model="form.address">
                </v-input>
                <v-select id="edit-office"
                          label="事業所"
                          v-model="form.office_id">
                    <option value="" :selected="form.office_id === ''">選択してください</option>
                    <option :value="office.id" v-for="office in offices" :key="office.id" :selected="form.office_id === office.id">
                        {{ office.attributes.name }}
                    </option>
                </v-select>
            </div>
        </form>
        <template #footer>
            <button type="button" class="btn btn-dark" @click="updateFacility" :disabled="sending">更新</button>
        </template>
    </CModal>
</template>

<script>
import VInput from "@/components/Forms/VInput";
import VSelect from "@/components/Forms/VSelect";
import ApiRouter from "@/components/Systems/ApiRouter";
import ErrorHandler from "@/components/Systems/ErrorHandler";

export default {
    name: "EditFacilityModal",
    components: {VSelect, VInput},
    mixins:[ApiRouter, ErrorHandler],
    props: ['offices'],
    data: function () {
        return {
            isOpened: false,
            sending: false,
            id: '',
            form: {
                name: '',
                address: '',
                office_id: '',
                service_type: ''
            }
        }
    },
    methods: {
        openModal: function (facility) {
            this.isOpened = true;
            this.initForm(facility);
            this.clearErrors();
        },
        initForm: function (facility) {
            this.id = facility.id;
            this.form.name = facility.attributes.name;
            this.form.address = facility.attributes.address;
            this.form.office_id = facility.attributes.office_id;
            this.form.service_type = facility.attributes.service_type;
        },
        updateFacility: function () {
            this.showIndicator('更新中');
            this.sending = true;

            axios.patch(this.routes.facility(this.id), this.form)
                .then(response => {
                    console.log(response.data.data);
                    this.$emit('facility-has-updated', response.data.data);
                    this.showSuccessPopup('更新しました');
                    this.clearErrors();
                    this.isOpened = false;
                })
                .catch(error => {
                    this.handleErrorStatusCode(error);
                })
                .finally(() => {
                    this.sending = false;
                });
        }
    }
}
</script>

<style scoped>

</style>
